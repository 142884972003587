import $ from "jquery";
window.$ = window.jQuery = $;
import slick from "slick-carousel";
window.slick = slick;

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
window.lightGallery = lightGallery;
window.lgThumbnail = lgThumbnail;
window.lgZoom = lgZoom;

import mCustomScrollbar from "malihu-custom-scrollbar-plugin";
window.mCustomScrollbar = mCustomScrollbar;

import noUiSlider from "nouislider";
window.noUiSlider = noUiSlider;

import wNumb from "wnumb";
window.wNumb = wNumb;

import mask from "jquery-mask-plugin";
window.mask = mask;

import validate from "jquery-validation";
window.validate = validate;

import PerfectScrollbar from 'perfect-scrollbar';

require('./header/menu');
require('./footer/footer');
require('./library/slick');
require('./component/tile');
require('./component/selectCustom');
require('./component/addition');
require('./component/form');
require('./component/video');
require('./component/faqs');
require('./library/lazy');
require('./component/gtm');
require('./component/addition-specialists');

$(document).ready(function () {
    var btn = $('.js_button_up');
    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });
    btn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
    });


    var btnMenuDrop = document.querySelector('.menu_opener');
    var MenuDrop = document.querySelector('.header-drop-menu');

    if (btnMenuDrop != null) {
        btnMenuDrop.addEventListener('click', function (e) {
            MenuDrop.classList.toggle('open');
            btnMenuDrop.classList.toggle('open');
        });
    }

    var searchBtn = document.querySelector('.search-btn-js');
    var searchForm = document.querySelector('.search-block-js');
    var searchInput = document.querySelector('.search__input');

    if (searchBtn != null) {
        searchBtn.addEventListener('click', function (e) {
            searchForm.classList.toggle('open');
            searchBtn.classList.toggle('open');
            searchInput.focus();
        });
    }

    $(document).on('click', '#do_search', function (e) {
        e.preventDefault();
        const wrapper = $(this).closest('.js__valid_search__form');
        const input = wrapper.find('#input_search');
        const errorBlock = wrapper.find('.error_text');

        errorBlock.hide()

        if (input.val().trim().length >= 2) {
            wrapper.submit();
        } else {
            errorBlock.slideDown();
        }
    })
});


$(window).on('load', function () {
    const heightWindow = $('.my_site').outerHeight();
    if (heightWindow < $(window).height()) {
        let height = $(window).height() - heightWindow;

        $('.footer').css('margin-top', `${height}px`)
    }
});

$(document).ready(function () {
    $.extend($.validator.messages, {
        required: jqueryErrorValidate.required,
        remote: jqueryErrorValidate.remote,
        email: jqueryErrorValidate.email,
        url: jqueryErrorValidate.url,
        date: jqueryErrorValidate.date,
        dateISO: jqueryErrorValidate.dateISO,
        number: jqueryErrorValidate.number,
        digits: jqueryErrorValidate.digits,
        creditcard: jqueryErrorValidate.creditcard,
        equalTo: jqueryErrorValidate.equalTo,
        accept: jqueryErrorValidate.accept,
    });
})

function customScrollbar() {
    if ($(window).outerWidth() > 1024) {
        const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const customScrollbar = $('.js__custom_scrollbar');

        if (customScrollbar.length > 0) {
            customScrollbar.css('height', screenHeight - 50 + 'px');
            new PerfectScrollbar(customScrollbar[0]);
        }
    }
}

window.customScrollbar = customScrollbar;